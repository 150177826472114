import { styled } from '@mui/system'

const Wrapper = styled('div')`
	.BHSocialWrapper {
		display: flex;
		a {
			padding: 12px;
			margin: 0 5px;
			background: transparent;
			${({ theme }) => theme.breakpoints.up('md')} {
				padding: 22px;
				margin: 0 calc(var(--spacing) * 1.25);
			}
			&.icon-instagram {
				order: 1;
			}
			&.icon-facebook {
				order: 2;
			}
			&.icon-linkedin {
				order: 3;
			}
		}
	}
`

const SocialWrapper = styled('div')``

export { Wrapper, SocialWrapper }
