import MUIGrid from '@mui/material/Grid'
import { styled } from '@mui/system'
import SubmitButton from '../../molecules/button/submit-button'
import { ControlledInput } from '../../molecules/input'

const shouldForwardProp = (prop) => !['variant', 'type'].includes(prop)
const shouldForwardPropInput = (prop) => !['type'].includes(prop)
const shouldForwardPropButton = (prop) => !['inputlabels'].includes(prop)

const MUIGridStyled = styled(MUIGrid, { shouldForwardProp })`
	position: relative;
	align-items: flex-start;
	& .MuiFormControl-root {
		margin: 0;
		flex-direction: column;
		margin-right: ${({ variant }) => (variant === 'standard' ? '14px' : variant === 'compact' ? '0px' : null)};
	}
	& .MuiFormLabel-root {
		color: var(--color-primary);
		transform: translate(0, calc(var(--spacing) * 1.25)) scale(1);
		&.Mui-focused {
			transform: translate(0, -9.5px) scale(0.75);
		}
		&.MuiFormLabel-filled {
			transform: translate(0, -9.5px) scale(0.75);
		}
	}
`

const ControlledInputStyled = styled(ControlledInput, { shouldForwardPropInput })`
	& .MuiInputBase-root {
		width: 100%;
		border-radius: 0;
		background: unset;
		border-bottom: 1px solid var(--color-primary);
		${({ type }) => (type === 'compact' ? '& > input { padding: 7px 0px; }' : null)};
		&:hover,
		&:focus {
			background: unset;
		}
		&.Mui-error {
			border-bottom: 1px solid var(--color-red);
		}
	}
`

const SubmitIconButtonStyled = styled(SubmitButton, { shouldForwardPropButton })`
	position: absolute;
	top: 4px;
	right: 0;
	width: 27px;
	height: 27px;
	border-radius: 0;
	${({ inputlabels }) => (inputlabels !== '' ? 'margin-top: 23px' : null)};
	color: var(--color-primary);
	background: none;
	padding: 0;
	&:hover,
	&:focus {
		background: none;
		color: var(--color-primary);
	}
	.MuiSvgIcon-root {
		font-size: ${({ theme }) => theme.typography.pxToRem(24)};
	}
`

const Wrapper = styled('div')`
	& .MuiFormControlLabel-root {
		margin: 0;
	}
`

const SubmitButtonStyled = styled(SubmitButton, { shouldForwardPropButton })`
	${({ inputlabels }) => (inputlabels !== '' ? 'margin-top: 22px' : null)};
`

export { MUIGridStyled, ControlledInputStyled, SubmitIconButtonStyled, Wrapper, SubmitButtonStyled }
