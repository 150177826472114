import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useFormContext } from 'react-hook-form'
import { useFormFields } from '@bluheadless/ui-logic/src/hooks/form/useFormFields'
import { useNewsletterContext } from '@bluheadless/ui-logic/src/providers/newsletter'
import { bool, object, string } from 'prop-types'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import Typography from '../../atoms/typography'
import { ControlledCheckbox } from '../../molecules/checkbox'
import RichContent from '../../particles/rich-content'
import {
	ControlledInputStyled,
	MUIGridStyled,
	SubmitButtonStyled,
	SubmitIconButtonStyled,
	Wrapper,
} from './newsletter-form-mini.styled'

const NewsletterFormMini = ({ action, variant, inputProps, buttonProps, checkboxProps, iconButtonProps, success }) => {
	const {
		disclaimers: { marketing },
		newsletter: { enabled: newsletterEnabled },
	} = useConfig()
	const {
		reset,
		formState: { isSubmitSuccessful },
	} = useFormContext()
	const [{ email }] = useNewsletterContext()
	const { formatMessage } = useIntl()

	const { email: emailField, privacyPolicy } = useFormFields()

	useEffect(() => {
		if (isSubmitSuccessful && success) {
			reset()
		}
	}, [isSubmitSuccessful, reset, success])

	useEffect(() => {
		if (!email) {
			reset()
		}
	}, [email, reset])

	return newsletterEnabled ? (
		<>
			<MUIGridStyled container spacing={0} direction="row" wrap="nowrap" variant={variant}>
				<ControlledInputStyled
					type={variant}
					name={emailField.name}
					required
					{...inputProps}
					label={formatMessage({ id: 'forms_email_placeholder' })}
				/>
				{variant === 'standard' && (
					<SubmitButtonStyled
						inputlabels={inputProps?.label || ''}
						label={formatMessage({ id: 'forms_newsletter_subscribe_btn' })}
						{...buttonProps}
					/>
				)}
				{variant === 'compact' && (
					<SubmitIconButtonStyled
						inputlabels={inputProps?.label || ''}
						label={formatMessage({ id: 'forms_newsletter_subscribe_btn' })}
						{...iconButtonProps}
					/>
				)}
			</MUIGridStyled>
			{action === 'standard' && (
				<Wrapper>
					<ControlledCheckbox
						required
						name={privacyPolicy.name}
						label={
							<Typography sx={{ color: checkboxProps?.labelProps?.color }} component="div">
								<RichContent content={marketing} />
							</Typography>
						}
						{...checkboxProps}
					/>
				</Wrapper>
			)}
		</>
	) : null
}

NewsletterFormMini.defaultProps = {
	variant: 'standard',
	action: 'standard',
}

NewsletterFormMini.propTypes = {
	/**
	 *  Defines action, can be 'standard' to send the request directly  or 'dialog'
	 *  to fill out the complete form
	 */
	action: string.isRequired,
	/**
	 *  Defines variant style, can be 'standard' or 'compact'
	 */
	variant: string.isRequired,
	/**
	 * Defines the properties of the newsletter input field
	 */
	inputProps: object,
	/**
	 * Defines the properties of the subscribe button
	 */
	buttonProps: object,
	/**
	 * Defines the properties of the privacy checkbox
	 */
	checkboxProps: object,
	/**
	 * Defines the properties of the button icon if variant is 'compact'
	 */
	iconButtonProps: object,
	/**
	 *  Success is true if response status is 200
	 */
	success: bool.isRequired,
}

export default NewsletterFormMini
