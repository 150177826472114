/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const CheckboxOnIconSvg = forwardRef((props, svgRef) => (
	<svg
		id="prefix__Livello_2"
		data-name="Livello 2"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		xmlns="http://www.w3.org/2000/svg"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<defs id="prefix__defs1">
			<style id="style1">{'.prefix__cls-1{fill:#0f0f0f}'}</style>
		</defs>
		<g id="prefix__Livello_1-2" data-name="Livello 1" transform="translate(1.24 1.24)">
			<path className="prefix__cls-1" id="prefix__rect1" d="M8.02 8.02h5.47v5.47H8.02z" />
			<path className="prefix__cls-1" d="M21.52 21.52H0V0h21.52zM1 20.52h19.52V1H1z" id="prefix__path1" />
		</g>
	</svg>
))
CheckboxOnIconSvg.displayName = 'CheckboxOnIconSvg'

const CheckboxOnIcon = forwardRef((props, ref) => <Icon component={CheckboxOnIconSvg} ref={ref} {...props} />)
CheckboxOnIcon.displayName = 'CheckboxOnIcon'

CheckboxOnIcon.defaultProps = {
	...Icon.defaultProps,
}
CheckboxOnIcon.propTypes = {
	...Icon.propTypes,
}

export default CheckboxOnIcon
export { CheckboxOnIconSvg }
